document.addEventListener("DOMContentLoaded", function() {
    /* **** sticky **** */
    window.addEventListener("scroll", function() {
        const header = document.querySelector("header");
        if (window.scrollY > 150) {
            header.classList.add("nav-new");
        } else {
            header.classList.remove("nav-new");
        }
    });
    /* **** sticky **** */   

    /* **** scrolling to specific element ***** */
    document.querySelectorAll('[data-scroll-nav]').forEach(element => {
        element.addEventListener('click', () => {
            scrollToTargetAdjusted(element);
        });
    });
    /* **** End: scrolling to specific element ***** */ 

    /* *** Back To Top *** */
    window.addEventListener("scroll", function() {
        const myBtn = document.getElementById('myBtn');
        if (window.scrollY > 300) {
            myBtn.classList.add('show');
        } else {
            myBtn.classList.remove('show');
        }
    });
    
    document.querySelector('#myBtn').addEventListener('click', function(e) {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });    
    /* *** End: Back To Top *** */
});

document.addEventListener('alpine:initialized', () => {
    /* **** Testimonial Slider ***** */
    // $(".testimonial-slider").slick({
    //     arrows: true,
    //     loop: true,
    //     dots: false,
    //     autoplay: true,
    //     autoplaySpeed: 2500,
    //     speed:1000,
    //     infinite: true,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     vertical:true        
    // });

    // let testimonials = [
    //     {
    //         imagePath: 'assets/images/user-img.png'
    //     },
    //     {
    //         imagePath: 'assets/images/user-img.png'
    //     },
    //     {
    //         imagePath: 'assets/images/user-img.png'
    //     }
    // ];

    // testimonials.forEach((item) => {
    //     let content = `
    //     <div class="testimonial-box">
    //         <div class="test-box">
    //             <div class="test-user">
    //                 <img src="${item.imagePath}" alt="testimonial from client" />
    //             </div>

    //             <p>
    //                 I love <a href="https://twitter.com/brbytes" target="_blank">@brbytes</a> - fantastic training, and providing <span>#nonprofits</span> and <span>#students</span> free resources :) Thanks for
    //                 making <span>#brbytes #CS_education #LA</span>
    //             </p>

    //             <h4>John Doe</h4>
    //             <h5>Student <span>LSU Highschool</span></h5>

    //             <a href="#" target="_blank" class="twitbtn"><i class="fab fa-twitter fa-fw"></i></a>
    //         </div>
    //     </div>
    //     `;

    //     $('.testimonial-slider').slick('slickAdd', content);
    // })
    /* ***** End Testimonial Slider **** */

    /* **** Partners Slider ***** */
    new Swiper(".partner-slider", {
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      pagination: false,
      navigation: false,
      direction: "horizontal",
      speed: 1000,
      loopAddBlankSlides: true,
      autoplay: {
        delay: 2500,
      },
      breakpoints: {
        575: {
          slidesPerView: 2,
        },
        991: {
          slidesPerView: 4,
        },
        1199: {
          slidesPerView: 5,
        }
      },
    });
    /* ***** End Partners Slider **** */
});

// Uses data-scroll-nav, data-scroll-index, and data-scroll-offset attributes
function scrollToTargetAdjusted(element) {
    const scrollIndex = element.dataset.scrollNav;
    const targetElem = document.querySelector(`[data-scroll-index='${scrollIndex}']`)
    const headerOffset = element.dataset.scrollOffset || 80;
    const elementPosition = targetElem.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
  
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}
